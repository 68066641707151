<template lang="pug">
div.container
  div(v-if="!mailchimp.status")
    loading-spinner(message="Loading visitor and MailChimp data...")
  div(v-if="mailchimp.status")
    div.row
      h1 Edit Visitor
    br/
    div.row
      div.col-md-4.d-none.d-md-block
        b-list-group.mb-3
          b-list-group-item(active) Visitor Details
          b-list-group-item.d-flex.justify-content-between.align-items-center First name <span>{{visitor.firstName}}</span>
          b-list-group-item.d-flex.justify-content-between.align-items-center Last name <span>{{visitor.lastName}}</span>
          b-list-group-item.d-flex.justify-content-between.align-items-center Email <span>{{visitor.email}}</span>
          b-list-group-item.d-flex.justify-content-between.align-items-center Organization <span>{{visitor.organization}}</span>
          b-list-group-item.d-flex.justify-content-between.align-items-center Last Updated <span>{{visitor.lastUpdated}}</span>
        b-list-group.mb-3
          b-list-group-item(active) MailChimp Overview
          b-list-group-item.d-flex.justify-content-between.align-items-center Status <span>{{mailchimp.status}}</span>
          b-list-group-item.d-flex.justify-content-between.align-items-center(v-if="mailchimp.stats") Email Open Rate <span>{{mailchimp.stats.avg_open_rate}}</span>
        b-list-group(v-if="mailchimp.stats").mb-3
          b-list-group-item(active) MailChimp Groups
          b-list-group-item.d-flex.justify-content-between.align-items-center(v-for="interest in mailchimp.namedInterests" :key="interest.name") {{interest.name}} <span>{{interest.status}}</span>
        b-list-group.mb-3
          b-list-group-item(active) Remove Visitor From Database
          b-list-group-item.text-center
            p(v-if="globalState.userRole >= 4") Warning: can not be undone!
            p(v-else) Dangerous - for admins only
            b-button(variant="danger" @click="deleteVisitor(visitor)" :disabled="globalState.userRole < 4") Delete Visitor
      div.col-md-8
        b-form
          b-form-row
            b-form-group.col-sm(label="First Name *" label-for="firstName")
              b-input(type="text" name="firstName" v-model="visitor.firstName" required)
            b-form-group.col-sm(label="First Name *" label-for="lastName")
              b-input(type="text" name="lastName" v-model="visitor.lastName" required)
          b-form-row
            b-form-group.col-sm(label="Email *" label-for="email")
              b-input(type="email" name="email" v-model="visitor.email" required)
            b-form-group.col-sm(label="Organization" label-for="organization")
              b-input(type="text" name="organization" v-model="visitor.organization")
          b-form-row
            b-form-group.col-sm(label="MailChimp Mailing Lists" v-if="mailchimp.stats")
              b-form-checkbox(v-for="group in mailchimp.namedInterests" :key="group.id" v-model="visitor.interests[group.id]") {{group.name}}
          b-form-row.justify-content-center.m-4
            b-button(variant="success" @click="updateVisitor(visitor)" :disabled="!isFormValid") Update Visitor
</template>

<script>
import globalState from '@/main.js'

export default {
  data: function () {
    return {
      mailchimp: {
        stats: {}
      },
      oldEmail: null,
      visitor: {
        firstName: '',
        lastName: '',
        email: '',
        organization: '',
        interests: {},
        lastUpdated: null
      },
      globalState
    }
  },
  computed: {
    isFormValid: function () {
      let result = true
      if (this.visitor.firstName === '' || this.visitor.lastName === '' || this.visitor.email === '') { result = false }
      return result
    }
  },
  methods: {
    updateVisitor: function (visitor) {
      this.axios.put('/api/visitors/' + this.visitor._id, visitor)
        .then(res => {
          this.$notify({
            group: 'success',
            type: 'success',
            title: 'Visitor updated',
            text: this.visitor.firstName + ' is now up-to-date!'
          })

          this.$router.push({ path: '/events/visitors' })
        })
        .catch(err => {
          this.$notify({
            group: 'error',
            type: 'error',
            title: 'Updating visitor failed',
            text: err.response.data,
            duration: -1
          })
        })
    },
    deleteVisitor: function (visitor) {
      this.axios.delete('/api/visitors/' + this.visitor._id)
        .then(res => {
          this.$notify({
            group: 'success',
            type: 'success',
            title: 'Visitor deleted'
          })

          this.$router.push({ path: '/events/visitors' })
        })
        .catch(err => {
          this.$notify({
            group: 'error',
            type: 'error',
            title: 'Deleting event failed',
            text: err.response.data,
            duration: -1
          })
        })
    }
  },
  mounted: function () {
    this.axios.get('/api/visitors/' + this.$route.params.id)
      .then(res => {
        this.visitor = res.data
        this.visitor.oldEmail = res.data.email

        this.axios.post('/api/visitors/mailchimp/', { email: res.data.email })
          .then(res2 => {
            this.visitor.interests = res2.data.interests
            this.mailchimp = res2.data
          })
          .catch(err => {
            this.$notify({
              group: 'error',
              type: 'error',
              title: 'Fetching MailChimp data failed',
              text: err.response.data,
              duration: -1
            })
          })
      })
      .catch(err => {
        this.$notify({
          group: 'error',
          type: 'error',
          title: 'Fetching visitor failed',
          text: err.response.data,
          duration: -1
        })
      })
  }
}
</script>
